<template>
  <div>
    <div class="modal-payment" id="payment" :class="$root.classObject">
      <div class="modal-content-sc">
        <span class="close" @click="hideModal()"></span>
        <h5><img :src="path + notificationImage" /></h5>
        <h6>{{ packagePurchaseStatus }}</h6>
        <p>{{ notificationMessage }}</p>
        <div class="subscription-submit-btn">
          <router-link
            :to="{ path: redirectLink }"
            class="send-coin"
            tag="button"
            >Okay
          </router-link>
        </div>
      </div>
    </div>
    <!--    <div style="color: red;text-align: center;padding: 25px;" class="loader" v-if="$root.classObject.active">-->
    <!--      <div class="lds-spinner">-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--        <div></div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Success",
  data: () => {
    return {
      path: "/images/",
      notificationImage: "",
      packagePurchaseStatus: "",
      notificationMessage: "",
      redirectLink: ""
    };
  },
  async created() {
    const phone = localStorage.getItem("phone")
      ? localStorage.getItem("phone")
      : "";
    if (phone === "") {
      return this.$router.push({ name: "Registration" });
    }
    let statusCode = parseInt(this.$route.query.statuscode);
    let referenceCode = this.$route.query.referenceCode;
    //console.log(`statusCode = ${statusCode}, referenceCode= ${referenceCode}`);
    if (statusCode === 200 && referenceCode !== undefined) {
      let host = this.$root.url();
      const apiPath = "gp-ppp-reference-checker";
      let url = `${host}/${apiPath}?msisdn=${phone}&referenceCode=${referenceCode}`;
      let response = await axios.get(url);
      console.log(response);
      let { success, code } = response.data;
      console.log(code);
      if (success) {
        this.notificationImage = "congrats-icon.svg";
        this.packagePurchaseStatus = "Congratulations";
        this.notificationMessage = "Your subscription has been successful.";
        this.redirectLink = "/";
      } else {
        if (code === 3004) {
          localStorage.clear();
          return this.$router.push({ name: "Registration" });
        }
        this.notificationImage = "sorry-icon.svg";
        this.packagePurchaseStatus = "Sorry!";
        this.notificationMessage = "Your payment has been unsuccessful.";
        this.redirectLink = "purchase-coin";
      }
    } else if (statusCode === 500 && referenceCode !== undefined) {
      this.notificationImage = "sorry-icon.svg";
      this.packagePurchaseStatus = "Sorry!";
      this.notificationMessage = "Your payment has been unsuccessful.";
      this.redirectLink = "purchase-coin";
    } else {
      this.$root.classObject.active = true;
      this.$router.push({ name: "Home" });
    }
    return true;
  },
  methods: {
    hideModal() {
      this.$router.push({ path: this.redirectLink });
    }
  }
};
</script>

<style scoped>
.modal-payment {
  /*display: none;*/
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

.active {
  display: none;
}
</style>
